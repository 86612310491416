@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-dark {
  --color-primary-btn: linear-gradient(#57fe76, #1ebac4);
  --color-primary-bg: 19 19 28;
  --color-on-primary-bg: 30 30 41;
  --color-on-primary-bg-soft: 30 30 41;
  --color-on-primary-bg-softer: #656578;
  --color-primary-text: 255 255 255;
  --color-on-hover-primary-text: #6e6e89;

  --color-secondary: #ffff;
  --color-secondary-bg: #132125;
  --color-on-secondary: #ffff;

  --color-accent: #24383e;
  --color-nano-green: 87 254 118;
  --color-error: 239 83 80;
}

.theme-light {
  --color-primary-bg: #ffffff;
  --color-primary-text: var(--color-primary-100);
  --color-secondary-text: var(--color-primary-100);
  --color-primary-background: var(--color-primary-100);
  --color-secondary-background: var(--color-primary-100);
}

.container {
  max-width: 1260px !important;
}

.node-text {
  visibility: hidden !important;
}

.aux-line {
  stroke: #ed5521 !important;
  stroke-width: 9px !important;
}

.gm-style-cc {
  visibility: hidden !important;
}

.rc-collapse-header {
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  padding: 0 !important;
  padding-bottom: 20px !important;
  padding-top: 20px !important;
  /* color: rgb(125, 119, 119) !important; */
}

.rc-collapse-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ED5521'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E") !important;
}
.accordion-button:disabled:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23D1D5DB'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E") !important;
}

.RSPBstep {
  /* position: static !important; */
  margin-bottom: 16px !important;
}

div a[title='Open this area in Google Maps (opens a new window)'] {
  visibility: hidden !important;
}

.scrollbar-ED5521 {
  background-color: #ed5521 !important;
}

button[title='Tilt map'] {
  display: none !important;
  visibility: hidden !important;
}

.gmnoprint,
.gm-bundled-control,
.gm-bundled-control-on-bottom {
  display: none !important;
  visibility: hidden !important;
}
